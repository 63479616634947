import MaterialParkIcon from '../../../assets/images/views/interactive_view_section/icons/parcmateriel.svg';
import MaterialParkPhoneImage from '../../../assets/images/views/interactive_view_section/phone_images/cuma_parcmateriel/cuma_parcmateriel.png';
import MaterialParkPhoneImageLowDef from '../../../assets/images/views/interactive_view_section/phone_images/cuma_parcmateriel/cuma_parcmateriel_low_def.png';
import ReactivityIcon from '../../../assets/images/views/interactive_view_section/icons/reactivite.svg';
import RouteAtThePlotIcon from '../../../assets/images/views/interactive_view_section/icons/itineraire.svg';
import ObservationIcon from '../../../assets/images/views/interactive_view_section/icons/observation.svg';

const interactivePhoneData = ({
	materialParkAtOneSight: {
		icon: MaterialParkIcon,
		label: "Parc matériel en un coup d'œil",
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: MaterialParkPhoneImage,
		correspondingViewImageLowDef: MaterialParkPhoneImageLowDef
	},
	reactivity: {
		icon: ReactivityIcon,
		label: 'Plus grande réactivité',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: MaterialParkPhoneImage
	},
	routeAtThePlot: {
		icon: RouteAtThePlotIcon,
		label: 'Itinéraire à la parcelle',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: MaterialParkPhoneImage
	},
	observation: {
		icon: ObservationIcon,
		label: 'La bonne information au bon moment',
		description: `Localisez les matériels de votre CUMA en toute simplicité;

    et visualisez l'activité de chacun d'entre eux d'un simple coup d'œil`,
		correspondingViewImage: MaterialParkPhoneImage
	}
});

export default interactivePhoneData;
