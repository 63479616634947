import React, {
	useCallback, useEffect, useState, useMemo, memo
} from 'react';

import cn from 'classnames';

import Carousel from '../../../design_system/carousel/carousel';
import DownloadsLinks from '../../downloads_links/downloads_links'
import Interaction from '../interaction/interaction';
import ViewDevice from '../view_device/view_device';

import classes from './desktop_interactive_view_section.module.css';

const INTERACTION_WIDTH = 280;

const DesktopInteractiveViewSection = ({
	contentClassName,
	data,
	viewType,
	showDownloadsLinks,
	viewDeviceProps,
	reverseContent
}) => {
	const firstInteractionId = useMemo(() => Object.keys(data)[0], [data])
	const [selectedInteractionId, setSelectedInteractionId] = useState(
		firstInteractionId || null
	)
	const [showingCardInteractionId, setShowingCardInteractionId] = useState(null)
	const [showInteractionsAsRow, setShowInteractionsAsRow] = useState(false)
	const [showInteractionsAsCarousel, setShowInteractionsAsCarousel] = useState(
		false
	);

	const interactionsEntries = useMemo(() => Object.entries(data))
	const handleWindowResize = useCallback(() => {
		if (!window) {
			return
		}
		const { innerWidth } = window
		const { length: interactionsLength } = interactionsEntries
		if (innerWidth <= 1125) {
			if (!showInteractionsAsRow) {
				setShowInteractionsAsRow(true)
			}
			// 20 & 30 are container & section's padding.
			const interactionsTotalWidthSuperiorToWindowWidth = interactionsLength * INTERACTION_WIDTH >= innerWidth - (20 * 2 + 30 * 2)
			if (
				interactionsTotalWidthSuperiorToWindowWidth
        && !showInteractionsAsCarousel
			) {
				setShowInteractionsAsCarousel(true)
			} else if (
				!interactionsTotalWidthSuperiorToWindowWidth
        && showInteractionsAsCarousel
			) {
				setShowInteractionsAsCarousel(false)
			}
		} else if (setShowInteractionsAsRow) {
			setShowInteractionsAsRow(false)
			setShowInteractionsAsCarousel(false)
		}
	}, [
		interactionsEntries,
		viewType,
		showInteractionsAsRow,
		showInteractionsAsCarousel
	])

	useEffect(() => {
		handleWindowResize()
		if (!window) {
			return
		}
		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [viewType, showInteractionsAsRow, showInteractionsAsCarousel]);

	return (
		<div
			className={cn(
				classes.content,
				contentClassName,
				showInteractionsAsRow && classes.wrappedContent,
				reverseContent && classes.reversedContent
			)}
		>
			<div className={classes.interactionsWrapper}>
				<Interactions
					{...{
						data,
						selectedInteractionId,
						showingCardInteractionId,
						setSelectedInteractionId,
						setShowingCardInteractionId,
						showInteractionsAsRow,
						showInteractionsAsCarousel
					}}
				/>
				{showDownloadsLinks && !showInteractionsAsRow && (
					<DownloadsLinks className={classes.downloadsLinks} />
				)}
			</div>
			<ViewDevice
				{...{
					viewType,
					selectedInteractionId,
					data
				}}
				viewDeviceProps={{
					...viewDeviceProps,
					className: cn(
						viewDeviceProps && viewDeviceProps.className,
						reverseContent && classes.reversedViewDevice,
						showInteractionsAsRow && classes.viewDeviceAsRow,
						classes.viewDevice
					)
				}}
			>
				{showDownloadsLinks && showInteractionsAsRow && (
					<DownloadsLinks
						linkClassName={classes.downloadLink}
						className={classes.downloadsLinks}
					/>
				)}
			</ViewDevice>
		</div>
	);
}

const Interactions = memo(
	({
		data,
		selectedInteractionId,
		showingCardInteractionId,
		setSelectedInteractionId,
		setShowingCardInteractionId,
		showInteractionsAsRow,
		showInteractionsAsCarousel
	}) => {
		const entries = Object.entries(data);
		const items = entries.map(([interactionId, interaction]) => (
			<Interaction
				key={`ìnteraction_${interactionId}`}
				isSelected={selectedInteractionId === interactionId}
				showCard={showingCardInteractionId === interactionId}
				preventLeave={showInteractionsAsRow}
				{...{
					interactionId,
					setSelectedInteractionId,
					setShowingCardInteractionId
				}}
				{...interaction}
			/>
		));
		let content = null;
		if (showInteractionsAsCarousel) {
			content = <InCarouselInteractions {...{ items, entries, setSelectedInteractionId }} />
		} else {
			content = items;
		}
		return (
			<div
				className={
					cn(classes.interactionsContainer,
						showInteractionsAsRow && classes.interactionsContainerAsRow,
						showInteractionsAsCarousel && classes.interactionsContainerAsCarousel)}
				style={{
					height: `calc(200px * ${Math.ceil(entries.length / 2)})`
				}}
			>
				{content}
			</div>
		)
	}
);

const InCarouselInteractions = ({ items, entries, setSelectedInteractionId }) => {
	const [itemsLength, setItemsLength] = useState(2);
	const handleWindowResize = useCallback(() => {
		if (!window) {
			return;
		}
		const { innerWidth } = window;
		const newItemsLength = Math.floor((innerWidth - (20 * 2 + 30 * 2)) / (280 + 50)) || 1;
		if (itemsLength !== newItemsLength) {
			setItemsLength(newItemsLength)
		}
		setItemsLength(newItemsLength);
	}, [itemsLength]);
	useEffect(() => {
		if (!window) {
			return;
		}
		handleWindowResize()
		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [itemsLength]);
	const handleSliderChange = useCallback((index) => {
		const selectedInteractionId = entries[index][0];
		setSelectedInteractionId(selectedInteractionId);
	}, [entries]);
	return (
		<div
			style={{
				width: '100%',
				maxWidth: `${itemsLength * (280 + 50)}px`
			}}
		>
			<Carousel
				arrowClassName={classes.arrow}
				slidesToShow={itemsLength}
				dots={false}
				afterChange={handleSliderChange}
				{...itemsLength <= 1 && {
					centerMode: true,
					centerPadding: 0
				}}
			>
				{items.map(item => (
					<div className={classes.itemInCarousel}>
						{item}
					</div>
				))}
			</Carousel>
		</div>
	)
};

export default DesktopInteractiveViewSection
