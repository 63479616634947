import React from 'react';

import cn from 'classnames';

import Button from '../../../design_system/button/button';

import classes from './default_actions_buttons.module.css';

const DefaultActionsButtons = ({ className, buttonClassName }) => (
	<div className={cn(classes.buttonsContainer, className)}>
		<Button
			className={cn(classes.button, classes.withWhiteLabelButton, buttonClassName)}
			variant="outlined"
		>
			{'Créer un compte démo'}
		</Button>
		<Button
			className={cn(classes.button, buttonClassName)}
			color="primary"
			variant="contained"
		>
			{'Demander un devis gratuit >'}
		</Button>
	</div>
);

export default DefaultActionsButtons;
