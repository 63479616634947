import BonduellePicture from '../../../assets/images/views/testimonials_section/bonduelle/bonduelle.png';
import BonduellePictureLowDef from '../../../assets/images/views/testimonials_section/bonduelle/bonduelle_low_def.png';

import GouillartPicture from '../../../assets/images/views/testimonials_section/gouillart/gouillart.png';
import GouillartPictureLowDef from '../../../assets/images/views/testimonials_section/gouillart/gouillart_low_def.png';

import SoutardPicture from '../../../assets/images/views/testimonials_section/soutard/soutard.png';
import SoutardPictureLowDef from '../../../assets/images/views/testimonials_section/soutard/soutard_low_def.png';

export default {
	first: {
		url: 'https://www.youtube.com/watch?v=IgnfKt6yojM',
		tag: 'ETA GOUILLART',
		name: 'Philippe GOUILLART',
		title: 'Gérant',
		preview: BonduellePicture,
		previewLowDef: BonduellePictureLowDef
	},
	second: {
		url: 'https://www.youtube.com/watch?v=1f8Fd6FE5Lw',
		tag: 'ETA GOUILLART',
		name: 'Philippe GOUILLART',
		title: 'Gérant',
		preview: GouillartPicture,
		previewLowDef: GouillartPictureLowDef
	},
	third: {
		url: 'https://www.youtube.com/watch?v=q8IUpZt0ros',
		tag: 'ETA GOUILLART',
		name: 'Philippe GOUILLART',
		title: 'Gérant',
		preview: SoutardPicture,
		previewLowDef: SoutardPictureLowDef
	}
};
