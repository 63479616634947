import React, { memo, useMemo } from 'react'

import cn from 'classnames';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import testimonialsData from './testimonials_data';

import BackgroundImage from '../../../assets/images/views/testimonials_section/background/background.png';
import BackgroundImageLowDef from '../../../assets/images/views/testimonials_section/background/background_low_def.png';

import Button from '../../design_system/button/button';
import Section from '../../design_system/section/section';
import SectionTitle from '../../design_system/section/section_title/section_title';
import ParallaxLayer from '../../design_system/parallax_layer/parallax_layer';
import Carousel from '../../design_system/carousel/carousel';
import OptimisedImage from '../optimised_image/optimised_image';
import TestimonialItem from './testimonial_item/testimonial_item';

import classes from './testimonials_section.module.css';

const TestimonialsSection = ({
	setVisibleOnScroll = true,
	data = testimonialsData,
	background = BackgroundImage,
	backgroundLowDef = BackgroundImageLowDef,
	title = 'Ils témoignent',
	...other
}) => {
	const showAsCarousel = useMediaQuery('(max-width: 1120px)', { defaultMatches: true });
	const entries = useMemo(() => Object.entries(data), [data]);
	return (
		<Section
			withClassName={classes.container}
			{...{ setVisibleOnScroll }}
			{...other}
		>
			{({ showSection }) => (
				<>
					<ParallaxLayer className={classes.background}>
						<OptimisedImage
							className={classes.backgroundPicture}
							normal={background}
							lowDef={backgroundLowDef}
							alt="Karnott témoignages"
						/>
					</ParallaxLayer>
					<SectionTitle
						containerClassName={classes.titleContainer}
						className={classes.title}
						underlineClassName={classes.underline}
						dismissUnderline={showSection}
					>
						{title}
					</SectionTitle>
					<div
						className={cn(classes.testimonialsContainer, showAsCarousel && classes.testimonialsContainerAsCarousel)}
						{...showAsCarousel && {
							style: {
								width: Math.ceil(entries.length / 2) * (300 + 20 * 2)
							}
						}}
					>
						<TestimonialItems {...{ entries, showAsCarousel }} />
					</div>
					<div className={classes.buttonsContainer}>
						<Button
							className={classes.button}
							color="light"
							variant="outlined"
						>
							{'Voir toutes les vidéos >'}
						</Button>
					</div>
				</>
			)}
		</Section>
	);
}

const TestimonialItems = memo(({ entries, showAsCarousel }) => {
	const items = entries.map(([id, item]) => (
		<TestimonialItem
			key={`customer_type_${id}`}
			{...entries.length < 3 && {
				className: classes.largeItem
			}}
			{...item}
		/>
	))
	if (showAsCarousel) {
		return (
			<Carousel
				dots={false}
				slidesToShow={Math.ceil(entries.length / 2)}
				arrowClassName={classes.carouselArrow}
				responsive={[
					{
						breakpoint: 760,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							centerPadding: 0
						}
					}
				]}
			>
				{items}
			</Carousel>
		);
	}
	return items;
});

export default TestimonialsSection
