import React, {
	useCallback, useEffect, useRef, useState, useMemo
} from 'react'

import cn from 'classnames'
import { animated, config, useSpring } from 'react-spring'
import PlayIcon from '../../../assets/images/views/play_video/play.svg'

import palette from '../../../utils/color_palette'

import classes from './play_video.module.css'

const DEFAULT_SCALE_SPRING_PROPS = {
	scale: 1
}

const PlayVideo = ({
	className,
	textClassName,
	color = 'emerald',
	textColor = 'light',
	pulseColor = color,
	title = 'Voir la vidéo',
	duration = '2:34',
	changeTextColorOnHover
}) => {
	const [running, setRunning] = useState(1)
	const timeout = useRef()
	const rgb = palette[color].rgb[500].join(',')
	const pulseRgb = palette[pulseColor].rgb[500].join(',')
	const { boxShadow } = useSpring({
		config: config.molasses,
		from: {
			boxShadow: `0 0 0 0px rgba(${pulseRgb}, 0.6)`
		},
		to: {
			boxShadow: `0 0 0 15px rgba(${pulseRgb}, 0)`
		},
		onRest: () => setRunning(0),
		reset: running === 0
	})

	const [{ scale }, setScaleSpringProps] = useSpring(
		() => DEFAULT_SCALE_SPRING_PROPS
	)

	const handleMouseEnter = useCallback(() =>
		setScaleSpringProps(() => ({
			scale: 1.2
		})))

	const handleMouseLeave = useCallback(() =>
		setScaleSpringProps(() => ({
			scale: 1
		})))

	useEffect(() => {
		if (running === 0) {
			setRunning(1)
		}
	}, [running === 0, timeout.current])

	return (
		<div className={cn(className, classes.container)}>
			<animated.button
				className={classes.playIconContainer}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onFocus={handleMouseEnter}
				onBlur={handleMouseLeave}
				style={{
					color: `rgb(${rgb})`,
					boxShadow,
					transform: scale.interpolate(
						value => `scale3d(${value}, ${value}, ${value})`
					)
				}}
			>
				<PlayIcon />
			</animated.button>
			<MaybeColorChangingText
				{...{
					textClassName,
					title,
					duration,
					textColor,
					changeTextColorOnHover
				}}
			/>
		</div>
	)
}

const Text = ({
	component: Component = 'div', textClassName, title, duration, ...other
}) => (
	<Component className={cn(classes.text, textClassName)} {...other}>
		<div className={classes.title}>{title}</div>
		<div className={classes.duration}>{duration}</div>
	</Component>
)

const ColorChangingText = ({ textColor, changeTextColorOnHover, ...other }) => {
	const defaultTextColor = useMemo(
		() => palette[textColor] && palette[textColor][500],
		[textColor]
	)
	const [{ color }, setColorSpringProps] = useSpring(() => ({
		color: defaultTextColor
	}))
	const setColorChange = useCallback(() =>
		setColorSpringProps(() => ({
			color: palette[changeTextColorOnHover][500]
		})))
	const setDefaultColor = useCallback(() =>
		setColorSpringProps(() => ({
			color: defaultTextColor
		})))
	return (
		<Text
			component={animated.div}
			style={{
				color
			}}
			onMouseEnter={setColorChange}
			onMouseLeave={setDefaultColor}
			onFocus={setColorChange}
			onBlur={setDefaultColor}
			{...other}
		/>
	)
}

const MaybeColorChangingText = ({
	changeTextColorOnHover,
	textColor,
	...other
}) => {
	if (changeTextColorOnHover) {
		return (
			<ColorChangingText
				{...{ changeTextColorOnHover, textColor }}
				{...other}
			/>
		)
	}
	return (
		<Text
			style={{
				color: palette[textColor] && palette[textColor][500]
			}}
			{...other}
		/>
	)
}

export default PlayVideo
