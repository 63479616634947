import React from 'react';

import DownloadsLinks from '../../downloads_links/downloads_links'
import Interaction from '../interaction/interaction';
import ViewDevice from '../view_device/view_device';

import classes from './mobile_interactive_view_section.module.css';

const MobileInteractiveViewSecion = ({
	data,
	viewType,
	showDownloadsLinks,
	viewDeviceProps
}) => (
	<div className={classes.container}>
		{Object.entries(data).map(([interactionId, item]) => (
			<Entry
				key={`mobile_interactive_view_entry_${interactionId}`}
				{...{ interactionId, data, viewType }}
				{...item}
			/>
		))}
	</div>
)

const Entry = ({
	icon,
	label,
	description,
	viewType,
	data,
	interactionId
}) => (
	<div className={classes.entryContainer}>
		<Interaction
			isSelected
			dismissCard
			{...{
        icon,
      	label,
      	description,
        interactionId
      }}
		/>
		<ViewDevice
			{...{ viewType, data }}
			selectedInteractionId={interactionId}
		/>
	</div>
)

export default MobileInteractiveViewSecion;
