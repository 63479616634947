import React, {
	memo, useCallback, useEffect, useMemo, useState
} from 'react'

import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Section from '../../design_system/section/section'
import SectionTitle from '../../design_system/section/section_title/section_title';
import DesktopInteractiveViewSection from './desktop_interactive_view_section/desktop_interactive_view_section';
import MobileInteractiveViewSection from './mobile_interactive_view_section/mobile_interactive_view_section';

import interactivePhoneData from './interactive_view_data';

import classes from './interactive_view_section.module.css'

const INTERACTION_WIDTH = 280;

const InteractiveViewSection = ({
	title = 'Des fonctionnalités conçues pour vous simplifier la vie',
	sectionClassName,
	titleClassName,
	contentClassName,
	data = interactivePhoneData,
	viewType = 'phone',
	showDownloadsLinks,
	viewDeviceProps,
	reverseContent,
	children
}) => {
	const isMobileLayout = useMediaQuery('(max-width: 550px)', { defaultMatches: true });
	return (
		<div className={cn(classes.container, classes[`${viewType}Container`])}>
			<Section
				withClassName={cn(classes.section, sectionClassName)}
			>
				<SectionTitle
					containerClassName={classes.sectionTitleContainer}
					className={cn(classes.sectionTitle, titleClassName)}
					dismissUnderline
				>
					{title}
				</SectionTitle>
				<Content
					{...{
						isMobileLayout,
						contentClassName,
						data,
						viewType,
						showDownloadsLinks,
						viewDeviceProps,
						reverseContent
					}}
				/>
				{children}
			</Section>
		</div>
	)
}

const Content = ({
	isMobileLayout,
	contentClassName,
	data,
	viewType,
	showDownloadsLinks,
	viewDeviceProps,
	reverseContent
}) => {
	if (isMobileLayout) {
		return (
			<MobileInteractiveViewSection
				{...{
					data,
					viewType,
					showDownloadsLinks,
					viewDeviceProps
				}}
			/>
		);
	}
	return (
		<DesktopInteractiveViewSection
			{...{
				contentClassName,
				data,
				viewType,
				showDownloadsLinks,
				viewDeviceProps,
				reverseContent
			}}
		/>
	);
}

export default InteractiveViewSection;
