import React, { useCallback, useRef } from 'react';

import cn from 'classnames';

import Button from '../../design_system/button/button';
import ParallaxLayer from '../../design_system/parallax_layer/parallax_layer';
import SimaSticker from '../sima_sticker/sima_sticker';
import ImportantActualityPopup from '../important_actuality_popup/important_actuality_popup';
import OptimisedImage from '../optimised_image/optimised_image';

import BackgroundImage from '../../../assets/images/pages/index/header/background.png';
import BackgroundImageLowDef from '../../../assets/images/pages/index/header/background_low_def.png';

import classes from './header.module.css';

const DefaultActionsButtons = () => (
	<>
		<Button
			className={classes.button}
			variant="outlined"
			style={{
				color: '#fff'
			}}
		>
			{'Créer un compte démo'}
		</Button>
		<Button
			className={classes.button}
			color="emerald"
			variant="contained"
		>
			{'Demander un devis gratuit >'}
		</Button>
	</>
);

const Header = ({
	className,
	dismissButtons,
	background = BackgroundImage,
	backgroundLowDef = BackgroundImageLowDef,
	parallaxLayerProps,
	actionsButtons = <DefaultActionsButtons />,
	titleClassName,
	backgroundClassName,
	title = 'Oubliez vos carnets de travaux.',
	children
}) => (
	<div className={cn(classes.container, className)}>
		<Background
			{...{
				background,
				backgroundLowDef,
				backgroundClassName,
				parallaxLayerProps
			}}
		/>
		<div className={classes.content}>
			<h1 className={cn(classes.title, titleClassName)}>
				{title}
			</h1>
			{!dismissButtons && (
				<div className={classes.buttonsContainer}>
					{actionsButtons}
				</div>
			)}
		</div>
		{children}
	</div>
);

const Background = ({
	background,
	backgroundLowDef,
	parallaxLayerProps,
	backgroundClassName
}) => {
	const updateParallaxLayerHeight = useRef(null);
	const getUpdateParallaxLayerHeight = useCallback((fn) => {
		updateParallaxLayerHeight.current = fn;
	});
	const handleOptimisedImageOnReady = useCallback(() => {
		if (typeof updateParallaxLayerHeight.current === 'function') {
			updateParallaxLayerHeight.current();
		}
	}, [updateParallaxLayerHeight.current])
	return (
		<ParallaxLayer
			{...{ getUpdateParallaxLayerHeight }}
			{...parallaxLayerProps}
		>
			<OptimisedImage
				className={cn(classes.background, backgroundClassName)}
				normal={background}
				lowDef={backgroundLowDef}
				onReady={handleOptimisedImageOnReady}
			/>
		</ParallaxLayer>
	);
};

export default Header;
