import React from 'react';

import InteractiveViewSection from '../../../small_views/interactive_view_section/interactive_view_section';

import data from './industriels_interaction_phone_data';

import classes from './industriels_interaction_phone_section.module.css';

const AgriInteractivePhoneSection = () => (
	<InteractiveViewSection
		showDownloadsLinks
		viewType="phone"
		title="Titre introductif pour décrire l’atout des fonctionnalités pour cette cible"
		viewDeviceProps={{
			className: classes.viewDevice
		}}
		{...{ data }}
	/>
);

export default AgriInteractivePhoneSection;
