import PrenomNomPicture from '../../../../assets/images/pages/solutions/industriels/testimonials_section/prenom_nom/prenom_nom.png';
import PrenomNomPictureLowDef from '../../../../assets/images/pages/solutions/industriels/testimonials_section/prenom_nom/prenom_nom_low_def.png';

import HenriBenardPicture from '../../../../assets/images/pages/solutions/eta/testimonials_section/henri_benard/henri_benard.png';
import HenriBenardPictureLowDef from '../../../../assets/images/pages/solutions/eta/testimonials_section/henri_benard/henri_benard_low_def.png';

export default {
	prenomNom: {
		url: 'https://www.youtube.com/watch?v=IgnfKt6yojM',
		tag: 'Sede Environnement',
		name: 'Thierry Baillet',
		title: 'Poste occupé',
		preview: PrenomNomPicture,
		previewLowDef: PrenomNomPictureLowDef,
    videoDuration: '3:05'
	},
	henriBenard: {
		url: 'https://www.youtube.com/watch?v=q8IUpZt0ros',
		tag: 'Bonduelle',
		name: 'Henri Benard',
		title: 'Responsable des achats agronomiques',
		preview: HenriBenardPicture,
		previewLowDef: HenriBenardPictureLowDef,
    videoDuration: '2:22'
	}
};
