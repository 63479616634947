import React from 'react'

import cn from 'classnames'
import { animated, config, useTransition } from 'react-spring'

import OptimisedImage from '../../optimised_image/optimised_image'

import PhoneLayerImage from '../../../../assets/images/views/interactive_view_section/ressources/phone_layer.png'
import DesktopLayerImage from '../../../../assets/images/views/interactive_view_section/ressources/desktop_layer.png'

import classes from './interaction_view_device.module.css'

const InteractionViewPhone = ({
	className,
	screenImage,
	screenImageLowDef,
	screenImageId,
	viewType,
	children
}) => {
	const isDesktop = viewType === 'desktop'
	return (
		<div
			className={cn(
				classes.columnContainer,
				className,
				isDesktop && classes.desktopColumnContainer
			)}
		>
			<div
				className={cn(classes.container, isDesktop && classes.desktopContainer)}
			>
				<img
					className={classes.image}
					src={isDesktop ? DesktopLayerImage : PhoneLayerImage}
					alt="Karnott - support applicatif"
				/>
				<ScreenImage
					{...{
						screenImage,
						screenImageLowDef,
						screenImageId,
						isDesktop
					}}
				/>
			</div>
			{children}
		</div>
	)
}

const ScreenImage = ({
	screenImage,
	screenImageLowDef,
	screenImageId,
	isDesktop
}) => {
	const transitions = useTransition(
		{ screenImage, screenImageLowDef },
		() => `screen_image_${screenImageId}`,
		{
			from: {
				opacity: 0,
				...(!isDesktop && {
					transform: 'translate3d(-100%, 0, 0)'
				})
			},
			enter: {
				opacity: 1,
				...(!isDesktop && {
					transform: 'translate3d(0, 0, 0)'
				})
			},
			leave: { opacity: 0 }
		}
	)
	return (
		<div className={classes.screenImageContainer}>
			{transitions.map(
				({ item, props, key }) =>
					item
          && item.screenImage && (
<OptimisedImage
							{...{ key }}
							className={classes.screenImage}
							imageComponent={animated.img}
							style={props}
							normal={item.screenImage}
							lowDef={item.screenImageLowDef}
							alt="Karnott - vue application"
						/>
					)
			)}
		</div>
	)
}

export default InteractionViewPhone
