import React, { useCallback } from 'react';

import cn from 'classnames';
import { animated, useSpring } from 'react-spring';

import Card from '../../../design_system/card/card';

import classes from './interaction.module.css';

const Interaction = ({
	icon: Icon,
	preventLeave = false,
	label,
	description,
	isSelected,
	interactionId,
	setSelectedInteractionId,
	setShowingCardInteractionId,
	cardClassName,
	dismissCard,
	showCard
}) => {
	const bottomLineSpringProps = useSpring({
		scale: isSelected ? 1 : 0.6,
		opacity: isSelected ? 1 : 0
	});
	const textSpringProps = useSpring({
		color: isSelected || showCard ? '#fff' : '#8cc047'
	});
	const contentSpringProps = useSpring({
		opacity: showCard ? 0 : 1
	});
	const handleMouseEnter = useCallback(() => {
		if (typeof setShowingCardInteractionId === 'function') {
			setShowingCardInteractionId(null);
		}
		if (typeof setSelectedInteractionId === 'function') {
			setSelectedInteractionId(interactionId);
		}
	}, [setShowingCardInteractionId, setSelectedInteractionId]);
	const handleMouseLeave = useCallback(() => {
		if (preventLeave) {
			return;
		}
		if (typeof setSelectedInteractionId === 'function') {
			setSelectedInteractionId(null);
		}
		if (showCard && typeof setShowingCardInteractionId === 'function') {
			setShowingCardInteractionId(null);
		}
	}, [showCard, preventLeave, setSelectedInteractionId, setShowingCardInteractionId]);

	const setShowCard = useCallback((shouldShowCard) => {
		if (typeof setShowingCardInteractionId === 'function') {
			setShowingCardInteractionId(shouldShowCard ? interactionId : null);
		}
	}, [interactionId, setShowingCardInteractionId]);

	const handleClick = useCallback(() => setShowCard(!showCard), [showCard]);
	return (
		<button
			className={cn(classes.container, isSelected && classes.selectedContainer)}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			onBlur={handleMouseLeave}
			onClick={handleClick}
			type="button"
		>
			<animated.div
				className={classes.content}
				style={contentSpringProps}
			>
				<Icon />
				<animated.span
					className={classes.label}
					style={textSpringProps}
				>
					{label}
				</animated.span>
				<animated.div
					className={classes.bottomLine}
					style={{
						transform: bottomLineSpringProps.scale.interpolate(value => `scale3d(${value}, ${value}, ${value})`),
						opacity: bottomLineSpringProps.opacity
					}}
				/>
			</animated.div>
			{!dismissCard && (
				<>
					<div className={classes.bottomRightIconContainer}>
						<BottomRightIcon
							style={textSpringProps}
							{...{
								showCard,
								setShowCard
							}}
						/>
					</div>
					<DescriptionCard {...{ cardClassName, showCard, description }} />
				</>
			)}
		</button>
	);
};

const DEFAULT_CIRCLE_SPRING_PROPS = ({
	opacity: 0,
	scale: 0.6
});

const DEFAULT_BOTTOM_RIGHT_ICON_SPRING_PROPS = ({
	scale: 1
});

const BottomRightIcon = ({
	showCard, setShowCard, style, ...other
}) => {
	const [circleSpringProps, setCircleSpringProps] = useSpring(() => DEFAULT_CIRCLE_SPRING_PROPS);
	const [springProps, setSpringProps] = useSpring(() => DEFAULT_BOTTOM_RIGHT_ICON_SPRING_PROPS);

	const handleMouseEnter = useCallback(() => setCircleSpringProps(() => ({
		opacity: 1,
		scale: 1
	})));
	const handleMouseLeave = useCallback(() => setCircleSpringProps(() => DEFAULT_CIRCLE_SPRING_PROPS));
	const handleMouseDown = useCallback(() => setSpringProps(() => ({
		scale: 0.85
	})));
	const handleMouseUp = useCallback(() => setSpringProps(() => DEFAULT_BOTTOM_RIGHT_ICON_SPRING_PROPS));
	const handleIconClick = useCallback(() => setShowCard(!showCard), [showCard]);

	return (
		<animated.span
			className={classes.bottomRightIcon}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			style={{
				...style,
				transform: springProps.scale.interpolate(value => `scale3d(${value}, ${value}, ${value})`)
			}}
			{...other}
		>
			{showCard ? '-' : '+'}
			<animated.div
				className={classes.bottomRightIconCircle}
				style={{
					opacity: circleSpringProps.opacity,
					transform: circleSpringProps.scale.interpolate(value => `scale3d(${value}, ${value}, ${value})`)
				}}
				onClick={handleIconClick}
			/>
		</animated.span>
	);
}

const DescriptionCard = ({ cardClassName, showCard, description }) => {
	const springProps = useSpring({
		opacity: showCard ? 1 : 0
	});
	return (
		<Card
			component={animated.div}
			className={cn(classes.card, cardClassName)}
			style={springProps}
		>
			{description}
		</Card>
	);
}

export default Interaction;
