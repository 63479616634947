import React from 'react';

import InteractionViewDevice from '../interaction_view_device/interaction_view_device'

const ViewDevice = ({
	viewType,
	selectedInteractionId,
	data,
	viewDeviceProps,
	children
}) => {
	const currentInteraction = data && data[selectedInteractionId]
	let screenImage = null
	let screenImageLowDef = null
	if (currentInteraction) {
		({
			correspondingViewImage: screenImage,
			correspondingViewImageLowDef: screenImageLowDef
		} = currentInteraction)
	}
	return (
		<InteractionViewDevice
			screenImageId={selectedInteractionId}
			{...{ screenImage, screenImageLowDef, viewType }}
			{...viewDeviceProps}
		>
			{viewDeviceProps && viewDeviceProps.children}
			{children}
		</InteractionViewDevice>
	)
}

export default ViewDevice;
