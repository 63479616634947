import React from 'react';

import HelmetLayout from '../../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../../components/layouts/main_layout/main_layout';
import WithGradientLayout from '../../components/layouts/with_gradient_layout/with_gradient_layout';

import IndustrielsHeader from '../../components/solutions/industriels/industriels_header/industriels_header';
import IndustrielsInteractivePhoneSection from '../../components/solutions/industriels/industriels_interactive_phone_section/industriels_interactive_phone_section';
import IndustrielsInteractiveDesktopSection from '../../components/solutions/industriels/industriels_interactive_desktop_section/industriels_interactive_desktop_section';
import IndustrielsTestimonialsSection from '../../components/solutions/industriels/industriels_testimonials_section/industriels_testimonials_section';

const Industriels = () => (
	<HelmetLayout>
		<MainLayout
			footerProps={{
				dismissedSections: ['create-demo-account']
			}}
		>
			<IndustrielsHeader />
			<WithGradientLayout>
				<IndustrielsInteractivePhoneSection />
				<IndustrielsInteractiveDesktopSection />
			</WithGradientLayout>
			<IndustrielsTestimonialsSection />
		</MainLayout>
	</HelmetLayout>
);

export default Industriels;
