import React from 'react';

import cn from 'classnames';

import PlayVideo from '../../play_video/play_video';
import Tag from '../../../design_system/tag/tag';
import OptimisedImage from '../../optimised_image/optimised_image';

import classes from './testimonial_item.module.css';

const TestimonialItem = ({
	className,
	url,
	tag,
	name,
	title,
	preview,
	previewLowDef,
	previewWebp,
	videoDuration = '2:34'
}) => (
	<div className={classes.container}>
		<div
			className={cn(
				classes.box,
				className
			)}
		>
			<OptimisedImage
				className={classes.preview}
				normal={preview}
				lowDef={previewLowDef}
				normalWebp={previewWebp}
				alt={`${name}'s preview'`}
			/>
			<div className={classes.content}>
				<Tag color="#111" label={tag} />
				<div className={classes.name}>
					{name}
				</div>
				<div className={classes.title}>
					{title}
				</div>
				<PlayVideo
					color="secondary"
					textColor="secondary"
					borderColor="secondary"
					duration={videoDuration}
				/>
			</div>
		</div>
	</div>
)

export default TestimonialItem;
